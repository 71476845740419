/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import Select from 'react-select';
import { useNavigate, useParams, Link } from 'react-router-dom';
 
const EditDigitMapping = () => {
    const { id } = useParams();
    const [name, setName] = useState('');
    const [company, setCompany] = useState([]);
    const [role, setRole] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [msg, setMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);    
    const [access, setAccess] = useState([]);
    const [numberList, setNumberList] = useState([]);
    const [formData, setFormData] = useState({
        cid: '',
        mapping_type: "0",
        number:'',
        alias:'',
        description:'',
        table_id: "0",
       translation: '',
       original_ani: '',
       ani_min_length:0,
       ani_max_length:40,
       original_dnis:'',
       dnis_min_length:0,
       dnis_max_length:40,
       translated_ani:'',
       translated_dnis:'',
       ani_action:'1',
       dnis_action:'1'
    });
   
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [next, setNext] = useState(false);

    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
      }, []);
    // useEffect(() => {
    //     if (token && id) {
    //       checAccess(id);
    //     }
    // }, [token, id]);
    
      useEffect(() => {
        if (token ) {
          getUserCompany();
          getDigitMappingDetail();
          getUserAccess();
        }
      }, [token ]);
      useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
        
    }, [access, token]);
    useEffect(() => {
        if(formData.cid){
            getAllActiveNumber();
            getCustomerDigitMappingAccess();
        }
      },[formData.cid]);
      
 
    const refreshToken = async () => {
        try {
            setPopupOpen(true);
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setPopupOpen(false);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
            
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axios.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const getAllActiveNumber = async () => {
        const response = await axios.get(`${baseUrl}/get-all-active-number/${formData.cid}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    //    console.log(response.data.number_list);
       setNumberList(response.data.number_list);
    //    console.log(company);
      
        
    }

    const getCustomerDigitMappingAccess = async () => {
        try {
            setPopupOpen(true);
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${formData.cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            
            if(role > 2 && response.data.digit_mapping_switch !== 1){
                navigate("/dashboard");
            }
            
            setPopupOpen(false);

        } catch (error) {
            // setLoading(false);
            setPopupOpen(false);
           console.log(error);
            
        }
    };
    
    // const checAccess = async(id) => {
    //     try {
    //         setPopupOpen(true);
    //         const response =  await axios.get(`${baseUrl}/check-access/${id}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }       
    //         });
            
    //         if(!response.data.isAccess){
    //             setNext(false);
    //             navigate('/dashboard');
              
    //         }else{
    //             setNext(true);
    //         }
            
            
            
    //     } catch (error) {
    //         // console.log("ddsd")
    //         // console.log(error);
    //         setNext(false);
    //         navigate('/dashboard');
    //     }
    //     // console.log(id);
    // }

    const checkAccess = async (access) => {
        if(!hasPermission('ordernewnumber')){
            navigate('/dashboard');
        }
    }
   
    const getUserCompany = async () => {
        
        const response = await axios.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        let allArray;
        if(role <= 2){
            // allArray = response.data.slice(1);
            allArray = response.data.filter(item => item.id !== 1);
        }else{
            allArray = response.data;
        }
        setCompany(allArray);
    }

    const getDigitMappingDetail = async () => {
        try {
            setPopupOpen(true);
            const response = await axios.get(`${baseUrl}/digit-mapping/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setPopupOpen(false);
            // console.log(response.data);
            const mappingData = response.data.mapping[0];
            
            setFormData({
                cid: mappingData.cid,
                mapping_type:mappingData.mapping_type,
                number:mappingData.number,
                alias:mappingData.alias,
                description: mappingData.description,
                table_id: mappingData.table_id,
               translation: mappingData.translation_type,
               original_ani: mappingData.original_ani,
               ani_min_length:mappingData.ani_min_length,
               ani_max_length:mappingData.ani_max_length,
               original_dnis:mappingData.original_dnis,
               dnis_min_length:mappingData.dnis_min_length,
               dnis_max_length:mappingData.dnis_max_length,
               translated_ani:mappingData.translated_ani,
               translated_dnis:mappingData.translated_dnis,
               ani_action:mappingData.ani_action,
               dnis_action:mappingData.dnis_action
            });
            

          
        }catch{
            // console.log(e);
            navigate('/dashboard');
        }
    };  
    const [validationErrors, setValidationErrors] = useState({
        cid: false,
        number:false,
        alias:false,
        original_ani: false,
        original_dnis:false

    });
    const formRef = useRef(null);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        let newValidationErrors = {
            cid: formData.cid === '',
            // number: formData.number === '',
            alias: formData.alias === '',
                        
          };
          if(formData.translation == ""){
            newValidationErrors.translation = formData.translation === ''      
          }
          if(formData.translation == 1){
            newValidationErrors.original_ani = formData.original_ani === ''      
          }
          if(formData.translation == 2){
            newValidationErrors.original_dnis = formData.original_dnis === ''           
          }
          if(formData.mapping_type == 2 && formData.translated_dnis){
            const regex = /^1\d{10}$/;
            newValidationErrors.translated_dnis = !regex.test(formData.translated_dnis);
          }
          setValidationErrors(newValidationErrors);
      
          // Check if there are any validation errors
          const hasErrors = Object.values(newValidationErrors).some((error) => error);
      
          if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          } else {
            // If no validation errors, proceed with form submission
            // console.log('Form submitted with data:', formData);
            // console.log(token);
           
             
              const combinedData = {
                formData: formData,
               
              };
              console.log(combinedData);
            try {
                setButtonDisabled(true);
                setPopupOpen(true);
                await axios.put(`${baseUrl}/digit-mapping/edit/${id}`,combinedData,{headers: {
                    Authorization: `Bearer ${token}`
                }});
                setIsSuccess(true);
                navigate("/digit-mapping");
            } catch (error) {
                setButtonDisabled(false);
                setPopupOpen(false);
                if (error.response) {
                    setIsSuccess(false);
                    setMsg(error.response.data.msg);
                }
            }
          }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        
    
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

    const handleSelectChange = (data, option) => {
        
        setFormData((prevData) => ({
            ...prevData,
            [option.name] : data.value
        }));
        
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [option.name]: false
        }));
    }

    const handleSelectAliasForNumber = (data, option) => {
       
        const name = company.find((item) => item.id == formData.cid).short_name;
        const mapping_type = formData.mapping_type;
        let alias;
        if(mapping_type == 1){
            alias = name+"_"+data.value;
        }else if(mapping_type == 2){
            alias = name.substring(0, 22)+"_OFFNET"+"_"+data.value;
        }

        console.log(data.value);
        const did_order = numberList.find((item) => item.tn_number == data.value).did_order;
        let table_id;
        if(did_order == 0){
            table_id = 2;
        }else{
            table_id = 1;
        }
        setFormData({... formData, alias : alias, table_id : table_id, number: data.value});
    }

    const handleTranslationType = (e) => {
        const type = e.target.value;
        let original_ani;
        let original_dnis;
        let ani_action;
        let dnis_action;
        if(type == 1){
            original_ani = formData.number;
            original_dnis = "ANY";
            ani_action = 1;
            dnis_action = 2;

        }else if(type == 2){
            original_ani = "ANY";
            original_dnis = formData.number;
            ani_action = 2;
            dnis_action = 1;
        }
        setFormData({... formData, dnis_action:dnis_action,ani_action:ani_action,original_ani : original_ani, original_dnis : original_dnis, translation: e.target.value});
    }
     
    // useEffect(() => {
    //     if(formData.mapping_type == 2){
    //         const syntheticEvent = {
    //             target: {
    //                 value: 2 // Set the value to 2
    //             }
    //         };
            
    //         // Call the handleTranslationType function with the synthetic event
    //         handleTranslationType(syntheticEvent);
    //     }
        
    // }, [formData.mapping_type, formData.number]);
 
    return (
        <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page admin-new-addstaff-page">
                    <div className="fullrow">
                        <div className="row">
                            <div className="col-6">
                                <div className="left-title">
                                    <h1>Edit DMT</h1>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="addnew-button">
                                    <Link to="/digit-mapping"><span>&larr;</span> Back</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                            <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p>
                                <div className="edit-staff-data-form add-new-staff-form">
                                    <form className="form-box" onSubmit={handleSubmit} ref={formRef}>
                                        <div className="common-white-shadow-background">
                                            <div className="innerboxwidth">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h3>Digit Mapping</h3>
                                                    </div>
                                                    <div className="col-12">
                                                        <label>Select Company</label>
                                                        <select name="cid" id="user_role" disabled value={formData.cid} onChange={handleInputChange} className={validationErrors.cid ? 'invalid' : ''} >
                                                            <option value="">Select Company</option>
                                                                {company.map((comp) => (                                                                    
                                                                    <option key={comp.id} value={comp.id}>
                                                                        {comp.cname}
                                                                    </option>  
                                                            ))}
                                                        </select> 
                                                        {validationErrors.cid && <div className="error error-message">Please select a company.</div>}
                                                    </div>
                                                   
                                                    <div className="col-12">
                                                        <label>Digit Mapping type</label>
                                                        <select name="mapping_type" id="mapping_type"  value={formData.mapping_type} onChange={handleInputChange} className={validationErrors.mapping_type ? 'invalid' : ''} >
                                                            <option value="">Select Type</option>
                                                            <option value="1">Standard Digit Mapping</option>
                                                            <option value="2">Offnet Digit Mapping</option>
                                                                  
                                                            
                                                        </select> 
                                                        {validationErrors.mapping_type && <div className="error error-message">Please select a digit mapping type.</div>}
                                                    </div>
                                                    {formData.mapping_type == "1" &&
                                                    <>
                                                    <div className="col-12">
                                                        <label>Select Number</label>
                                                         
                                                        <Select
                                                            classNamePrefix="select"
                                                            value={{value:formData.number, label: formData.number}}
                                                            name="number"
                                                            onChange={(selectedOption, name) => {
                                                                handleSelectChange(selectedOption, name);
                                                                handleSelectAliasForNumber(selectedOption, name);
                                                            }}
                                                            options={numberList.map(item => ({
                                                                value: item.tn_number,
                                                                label: item.tn_number
                                                            }))}
                                                        />
                                                        {validationErrors.number && <div className="error error-message">Please select a number.</div>}
                                                       
                                                    </div>
                                                    <div className="col-12">
                                                        <label>Alias</label>
                                                        <input type="text" name="alias" value={formData.alias}  readOnly onChange={handleInputChange} className={validationErrors.alias ? 'invalid' : ''} />
                                                        {validationErrors.alias && <div className="error error-message">Please Enter Alias Name.</div>}                                                         
                                                    </div>
                                                    <div className="col-12">
                                                        <label>Description</label>
                                                        <input type="text" name="description" value={formData.description}  onChange={handleInputChange} />                                                        
                                                        
                                                    </div>
                                                    <div className="col-12">
                                                        <label>Digit Mapping Table</label>
                                                        <select name="table_id" id="table_id"  value={formData.table_id} disabled onChange={handleInputChange} className={validationErrors.table_id ? 'invalid' : ''} >
                                                            <option value="">Select Table</option>
                                                            <option value="1">8xx conversions:1</option>
                                                            <option value="2">DID_DNIS_Translations:2</option>
                                                                  
                                                            
                                                        </select> 
                                                        {validationErrors.table_id && <div className="error error-message">Please select a digit mapping type.</div>}
                                                    </div>
                                                    <div className="col-12">
                                                        <label>Translation type</label>
                                                        <select name="translation" id="translation" value={formData.translation} onChange={(e) => {handleInputChange(e); handleTranslationType(e);}} className={validationErrors.translation ? 'invalid' : ''} >
                                                            <option value="">Select Type</option>
                                                            <option value="1">ANI</option>
                                                            <option value="2">DNIS</option>
                                                                  
                                                            
                                                        </select> 
                                                        {validationErrors.translation && <div className="error error-message">Please select a Translation Type.</div>}
                                                       
                                                    </div>
                                                    
                                                    {(formData.translation == 1 || formData.translation == 2) &&
                                                        <>
                                                            <div className="col-md-8">
                                                                <label>Origination ANI</label>
                                                                {formData.translation == 2 &&<><input type="text" name="original_ani" value={formData.original_ani}  onChange={handleInputChange} className={validationErrors.original_ani ? 'invalid' : ''} />
                                                                {validationErrors.original_ani && <div className="error error-message">Please enter Origination ANI.</div>}</>}
                                                                {formData.translation == 1 &&<>
                                                                {/* <select name="original_ani" id="number" disabled value={formData.original_ani}  onChange={handleInputChange} className={validationErrors.original_ani ? 'invalid' : ''} >
                                                                    <option value="">Select Number</option>
                                                                        {numberList.map((comp) => (                                                                    
                                                                            <option key={comp.id} value={comp.tn_number}>
                                                                                {comp.tn_number}
                                                                            </option>  
                                                                    ))}
                                                                </select>  */}
                                                                <Select
                                                                    classNamePrefix="select"
                                                                    name="original_ani"
                                                                    onChange={(selectedOption, name) => {
                                                                        handleSelectChange(selectedOption, name);
                                                                    }}
                                                                    value={{value: formData.original_ani, label : formData.original_ani}}
                                                                    options={numberList.map(item => ({
                                                                        value: item.tn_number,
                                                                        label: item.tn_number
                                                                    }))}
                                                                    isDisabled ={true}
                                                                />
                                                                {validationErrors.original_ani && <div className="error error-message">Please select a Origination ANI.</div>}</>}
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label>Minimum Length</label>
                                                                <input type="text" name="ani_min_length" value={formData.ani_min_length}  onChange={handleInputChange} className={validationErrors.ani_min_length ? 'invalid' : ''} />

                                                            </div>
                                                            <div className="col-md-2">
                                                                <label>Maximum Length</label>
                                                                <input type="text" name="ani_max_length" value={formData.ani_max_length}  onChange={handleInputChange} className={validationErrors.ani_max_length ? 'invalid' : ''} />

                                                            </div>
                                                            <div className="col-sm-12">
                                                                <label>Translated ANI</label>
                                                                <input type="text" name="translated_ani" value={formData.translated_ani}   onChange={handleInputChange} className={validationErrors.translated_ani ? 'invalid' : ''}/>                                                                
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <label>ANI Action</label>
                                                                <select name="ani_action" id="ani_action"  value={formData.ani_action} onChange={handleInputChange} className={validationErrors.ani_action ? 'invalid' : ''} >
                                                                    <option value="0">all</option>
                                                                    <option value="1">match</option>
                                                                    <option value="2">passthrough</option>
                                                                    <option value="3">prepend</option>
                                                                </select>                                                                 
                                                            </div>
                                                       
                                                    
                                                   
                                                        <div className="col-md-8">
                                                            <label>Origination DNIS</label>
                                                            {formData.translation == 1 &&<><input type="text" name="original_dnis" value={formData.original_dnis}  onChange={handleInputChange} className={validationErrors.original_dnis ? 'invalid' : ''} />
                                                            {validationErrors.original_dnis && <div className="error error-message">Please Enter Origination DNIS.</div>}</>}
                                                            {formData.translation == 2 && 
                                                        <>
                                                            <Select
                                                            classNamePrefix="select"
                                                            name="original_dnis"
                                                            value={{value: formData.original_dnis, label :formData.original_dnis}} 
                                                            onChange={(selectedOption, name) => {
                                                                handleSelectChange(selectedOption, name);
                                                            }}
                                                            options={numberList.map(item => ({
                                                                value: item.tn_number,
                                                                label: item.tn_number
                                                            }))}
                                                            isDisabled ={true}
                                                        /> 
                                                            {validationErrors.original_dnis && <div className="error error-message">Please Enter Origination DNIS</div>}</>}
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label>Minimum Length</label>
                                                            <input type="text" name="dnis_min_length" value={formData.dnis_min_length}  onChange={handleInputChange} className={validationErrors.dnis_min_length ? 'invalid' : ''} />

                                                        </div>
                                                        <div className="col-md-2">
                                                            <label>Maximum Length</label>
                                                            <input type="text" name="dnis_max_length" value={formData.dnis_max_length}  onChange={handleInputChange} className={validationErrors.dnis_max_length ? 'invalid' : ''} />

                                                        </div>                                                    
                                                        <div className="col-sm-12">
                                                            <label>Translated DNIS</label>
                                                            <input type="text" name="translated_dnis" value={formData.translated_dnis}   onChange={handleInputChange} className={validationErrors.translated_dnis ? 'invalid' : ''}/>

                                                        </div>

                                                        <div className="col-sm-12">
                                                            <label>DNIS Action</label>
                                                            <select name="dnis_action" id="dnis_action" value={formData.dnis_action}   onChange={handleInputChange} className={validationErrors.dnis_action ? 'invalid' : ''} >
                                                                <option value="0">all</option>
                                                                <option value="1">match</option>
                                                                <option value="2">passthrough</option>
                                                                <option value="3">noLNP</option>
                                                                <option value="4">prepend</option>

                                                            </select> 

                                                        </div>
                                                        
                                                        </>}
                                                    </>
                                                    }
                                                    {formData.mapping_type == "2" &&
                                                    <>
                                                    <div className="col-12">
                                                        <label>Select Number</label>
                                                        {/* <select name="number" id="number"  value={formData.number} onChange={(e) => {handleInputChange(e); handleAliasForNumber(e);}} className={validationErrors.number ? 'invalid' : ''} >
                                                            <option value="">Select Number</option>
                                                                {numberList.map((comp) => (                                                                    
                                                                    <option key={comp.id} value={comp.tn_number}>
                                                                        {comp.tn_number}
                                                                    </option>  
                                                            ))}
                                                        </select>  */}
                                                        <Select
                                                            classNamePrefix="select"
                                                            name="number"
                                                            value={{vale: formData.number, label : formData.number}}
                                                            onChange={(selectedOption, name) => {
                                                                handleSelectChange(selectedOption, name);
                                                                handleSelectAliasForNumber(selectedOption, name);
                                                            }}
                                                            options={numberList.map(item => ({
                                                                value: item.tn_number,
                                                                label: item.tn_number
                                                            }))}
                                                        />
                                                        {validationErrors.number && <div className="error error-message">Please select a number.</div>}
                                                       
                                                    </div> 
                                                    <div className="col-12">
                                                        <label>Alias</label>
                                                        <input type="text" name="alias" value={formData.alias}   className={validationErrors.alias ? 'invalid' : ''}  readOnly/>                                                        
                                                        {validationErrors.alias && <div className="error error-message">Please set valid alias.</div>}
                                                    </div>
                                                    <div className="col-12">
                                                        <label>Description</label>
                                                        <input type="text" name="description" value={formData.description}  onChange={handleInputChange} />                                                        
                                                        
                                                    </div>
                                                    <div className="col-12">
                                                        <label>Digit Mapping Table</label>
                                                        <select name="table_id" id="table_id"  value={formData.table_id} disabled onChange={handleInputChange} className={validationErrors.table_id ? 'invalid' : ''} >
                                                            <option value="">Select Table</option>
                                                            <option value="1">8xx conversions:1</option>
                                                            <option value="2">DID_DNIS_Translations:2</option>
                                                                  
                                                            
                                                        </select> 
                                                        {validationErrors.table_id && <div className="error error-message">Please select a digit mapping type.</div>}
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label>Origination ANI</label>
                                                        <input type="text" name="original_ani" value={formData.original_ani}  placeholder="Any" onChange={handleInputChange} className={validationErrors.original_ani ? 'invalid' : ''} />
                                                        {validationErrors.original_ani && <div className="error error-message">Please enter Origination ANI.</div>}
                                                        
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label>Minimum Length</label>
                                                        <input type="text" name="ani_min_length" value={formData.ani_min_length}  onChange={handleInputChange} className={validationErrors.ani_min_length ? 'invalid' : ''} />
                                                       
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label>Maximum Length</label>
                                                        <input type="text" name="ani_max_length" value={formData.ani_max_length}  onChange={handleInputChange} className={validationErrors.ani_max_length ? 'invalid' : ''} />
                                                                                                                
                                                    </div>                                                    
                                                    <div className="col-sm-12">
                                                        <label>Translated ANI</label>
                                                        <input type="text" name="translated_ani" value={formData.translated_ani}   onChange={handleInputChange} className={validationErrors.translated_ani ? 'invalid' : ''}/>
                                                        
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <label>ANI Action</label>
                                                        <select name="ani_action" id="ani_action"  value={formData.ani_action} onChange={handleInputChange} className={validationErrors.ani_action ? 'invalid' : ''} >
                                                            <option value="0">all</option>
                                                            <option value="1">match</option>
                                                            <option value="2">passthrough</option>
                                                            <option value="3">prepend</option>
                                                            
                                                        </select> 
                                                        
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label>Origination DNIS</label>
                                                        
                                                        {/* <select name="original_dnis" id="number"  disabled onChange={handleInputChange} value={formData.original_dnis} className={validationErrors.original_dnis ? 'invalid' : ''} >
                                                            <option value="">Select Number</option>
                                                                {numberList.map((comp) => (                                                                    
                                                                    <option key={comp.id} value={comp.tn_number}>
                                                                        {comp.tn_number}
                                                                    </option>  
                                                            ))}
                                                        </select>  */}
                                                        <Select
                                                            classNamePrefix="select"
                                                            value={{value: formData.original_dnis, label: formData.original_dnis}}
                                                            name="number"
                                                            onChange={(selectedOption, name) => {
                                                                handleSelectChange(selectedOption, name);
                                                            }}
                                                            options={numberList.map(item => ({
                                                                value: item.tn_number,
                                                                label: item.tn_number
                                                            }))}
                                                            isDisabled = {true}
                                                        />
                                                        {validationErrors.original_dnis && <div className="error error-message">Please Enter Origination DNIS</div>}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label>Minimum Length</label>
                                                        <input type="text" name="dnis_min_length" value={formData.dnis_min_length}  onChange={handleInputChange} className={validationErrors.dnis_min_length ? 'invalid' : ''} />
                                                        
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label>Maximum Length</label>
                                                        <input type="text" name="dnis_max_length" value={formData.dnis_max_length}  onChange={handleInputChange} className={validationErrors.dnis_max_length ? 'invalid' : ''} />
                                                                                                                
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <label>Translated DNIS</label>
                                                        <input type="text" name="translated_dnis" value={formData.translated_dnis}   onChange={handleInputChange} className={validationErrors.translated_dnis ? 'invalid' : ''}/>
                                                        {validationErrors.translated_dnis && <div className="error error-message">Please Enter value in 1NPANXXNNNN format</div>}
                                                    </div>
                                                    
                                                    <div className="col-sm-12">
                                                        <label>DNIS Action</label>
                                                        <select name="dnis_action" id="dnis_action" value={formData.dnis_action}   onChange={handleInputChange} className={validationErrors.dnis_action ? 'invalid' : ''} >
                                                            <option value="0">all</option>
                                                            <option value="1">match</option>
                                                            <option value="2">passthrough</option>
                                                            <option value="3">noLNP</option>
                                                            <option value="4">prepend</option>
                                                            
                                                        </select> 
                                                      
                                                    </div>
                                                    </>}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    
                                     
                                    <div className="col-12">
                                        <input type="submit" value="Update Digit Mapping" className={buttonDisabled ? 'yellow-button disabled-link' : 'yellow-button'}  />
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                
            </div>
        </div>
    )
}
 
export default EditDigitMapping