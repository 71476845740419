import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from 'axios';
import baseUrl from "../../BaseUrl";

// Set app element for accessibility
Modal.setAppElement("#root");

const PasswordResetModal = ({ userId, isOpen, onClose }) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isMatching, setIsMatching] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [validations, setValidations] = useState({
        minLength: false,
        hasLetter: false,
        hasSpecialChar: false,
        hasNumber: false
    });
    const [validationErrors, setValidationErrors] = useState({
        password: false,
        confirmPassword: false,
        passwordMatch: false

    });

    useEffect(() => {
        if (isOpen) {
            setPassword("");
            setConfirmPassword("");
            setIsMatching(true);
            setValidations({
                minLength: false,
                hasLetter: false,
                hasSpecialChar: false,
                hasNumber: false
            });
            setValidationErrors({
                password: false,
                confirmPassword: false,
                passwordMatch: false
            });
        }
    }, [isOpen]);
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);

        // Update validations based on the password
        setValidations({
            minLength: newPassword.length >= 8,
            hasLetter: /[a-zA-Z]/.test(newPassword),
            hasSpecialChar: /[!@#$%^&+=]/.test(newPassword),
            hasNumber: /[0-9]/.test(newPassword),

        });

        if (confirmPassword !== newPassword) {
            setIsMatching(false);
        } else {
            setIsMatching(true);
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);



        // Check if the confirmation password matches the password
        if (newConfirmPassword !== password) {
            setIsMatching(false);
        } else {
            setIsMatching(true);
        }
    };

  
    const handleSubmit = async (e) => {
        e.preventDefault();

        
        
        const newValidationErrors = {            
            password: password === '',
            confirmPassword: confirmPassword === '',    
        };
        setValidationErrors(newValidationErrors);

        const checkFiled = Object.values(newValidationErrors).some((error) => error);
        const hasErrors = Object.values(validations).filter((value) => value === true).length;
        
        
        if(!checkFiled && hasErrors == 4 && isMatching){
            try {
                await axios.post(`${baseUrl}/set-password`,{
                    user_id: userId,
                    password: password
                });
                setIsSuccess(true);
               
            } catch (error) {
                // console.log();
                setIsSuccess(false);
                
            }
            setTimeout(() => {
                onClose();
            }, 2000); 
            // onClose();
        }else{
            // formRef.current.querySelector('.invalid');
        }
    }
       

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Reset Password"
        >
            <div className='row col-sm-12'>
                <div className='col-sm-3 cancel-btn'><button onClick={onClose}>Close</button></div>
                <div className='col-sm-9 apply-all'><button className="save-btn" onClick={handleSubmit}>Submit</button></div>
            </div>
            {isSuccess === true && 
                        <div className="form-box">
                            <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>Password set successfully</p>
                        </div>
                        }
            <div className='row'>
                <div className="col-md-7 right-box">
                    <div className="field mt-5">
                        <label className="label">New Password</label>
                        <div className="controls">
                            <input type="password" className="input" value={password} onChange={handlePasswordChange} />
                            {validationErrors.password && <div className="error error-message">Please enter password.</div>}
                            {validationErrors.passwordPolicy && <div className="error error-message">Password must contain at minimum 8 characters, one special character, one alphabet, and one numeric character.</div>}
                        </div>
                    </div>
                    <div className="field mt-5">
                        <label className="label">Confrim Password</label>
                        <div className="controls">
                            <input type="password" className="input" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                            {validationErrors.confirmPassword && <div className="error error-message">Please enter confrim password.</div>}
                            {validationErrors.passwordMatch && <div className="error error-message">Password and confrim password not match.</div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row w-5">
  <ul >
    <li className={`col-12 ${validations.minLength ? 'valid' : 'invalid'}`}>
      Minimum 8 characters
    </li>
    <li className={`col-12 ${validations.hasLetter ? 'valid' : 'invalid'}`}>
      At least one letter
    </li>
    <li className={`col-12 ${validations.hasSpecialChar ? 'valid' : 'invalid'}`}>
      At least one special character (e.g., @, #, $)
    </li>
    <li className={`col-12 ${validations.hasNumber ? 'valid' : 'invalid'}`}>
      At least one number
    </li>
    {!isMatching && (
      <li className="col-12 error-message">Passwords do not match.</li>
    )}
  </ul>
</div>


           
        </Modal>
    );
};

export default PasswordResetModal;
