import React, { useEffect } from 'react';

const Popup = ({ data, onClose }) => {
  useEffect(() => {
    //console.log("Popup opened with data:", data); // Log the array of data for debugging
  }, [data]);

  return (
    <div className="popup-overlay">
      <div className="popup">
        <h2>FTC Information</h2><hr />
        {data && data.length > 0 ? (
          data.map((item, index) => (
            <div key={index}>
              <p>Phone Number: {item.Company_Phone_Number}</p>
              <p>City: {item.Consumer_City}</p>
              <p>State: {item.Consumer_State}</p>
              <p>Consumer Area Code: {item.Consumer_Area_Code}</p>
              <p>Subject: {item.Subject}</p>
              <p>Recorded Message Or Robocall: {item.Recorded_Message_Or_Robocall}</p>
              <hr /> {/* Optional: To separate data visually */}
            </div>
          ))
        ) : (
          <p>No data available</p>
        )}
        <button className="apply-btn" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Popup;
