/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import Header from "../Header";
import Navbar from "../Navbar";
import DatePicker from 'react-datepicker';
import Pagination from "react-js-pagination";
import jwt_decode from "jwt-decode";
import { useNavigate, Link } from 'react-router-dom';

const CitibankUptivityAnalytics = () => {
  const [name, setName] = useState('');
  const [timeZone, seTimeZone] = useState('');
  const [token, setToken] = useState('');
  const [userId, setUserId] = useState('');
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [role, setRole] = useState('');
  const [expire, setExpire] = useState('');
  const [access, setAccess] = useState([]);
    const [data, setData] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [folderPath, setFolderPath] = useState(["uptivity"]);
    const [folders, setFolders] = useState([]);
    const [files, setFiles] = useState([]);
    const [searchDate, setSearchDate] = useState("");
    const [searchEndDate, setSearchEndDate] = useState("");
    const [displayDate, setDisplayDate] = useState("");
    const [displayEndDate, setDisplayEndDate] = useState("");
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [fileList, setFileList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
      refreshToken();
      getUserAccess();
  }, []);

  useEffect(() => {
      
    if(role && userId){
      if((userId && role >= 2 && userId != 94 && userId != 95 && userId != 106)){          
        checkAccess();
      }
    }
  }, [role, userId]);
    useEffect(() => {
        getFileList(page, searchDate, searchEndDate,searchValue);
    }, [page]);
  
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const getUserAccess = async () => {
      try {
          const response = await axiosJWT.get(`${baseUrl}/user-access`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }       
          });
         
          console.log(response.data.results);
          setAccess(response.data.results);
          // setIsLoading(false);
          
      } catch (error) {
         console.log(error);
      //    setIsLoading(false);
          // if (error.response) {
          //     // setError("Unauthorized");
          //     navigate("/");  
          //     window.location.reload();
          // }
      }
    }
  
    const handleBreadcrumbClick = async(index) => {
      if(folderPath.slice(0, index + 1).length == 1){
        fetchData();
      }else{
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/get-folder-detail?name=${JSON.stringify(folderPath.slice(0, index + 1))}`);
        console.log(response.data.results);
        // setData(response.data.results);
        setFolders(response.data.folders);
        setFiles(response.data.files);
        setPopupOpen(false);
      }
     
      setFolderPath(folderPath.slice(0, index + 1));
    };
    

  
    // const getFolderByPath = (path) => {
    //   let folder = data[0];
    //   for (let i = 1; i < path.length; i++) {
    //     folder = folder.subfolder.find((f) => f.folder === path[i]);
    //   }
    //   return folder;
    // };
  
    const renderBreadcrumbs = () => {
      const processedPath = folderPath.map(folder => folder.endsWith('/') ? folder.slice(0, -1) : folder);
      return (
        <div>
          {processedPath.map((folder, index) => (
            <span key={index}>
              {index === 0 ? null : ' / '}
              <a className="breadcrumb-link"  onClick={() => handleBreadcrumbClick(index)}>{folder}</a>
            </span>
          ))}
        </div>
      );
    };
  
    const handleSearchDate = (date) => { 
      console.log(date);  
      const formattedDate =
      date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
       date.getDate().toString().padStart(2, '0');
    
       
      setSearchDate(formattedDate);
      setDisplayDate(date);
  };
  const handleSearchEndDate = (date) => { 
      console.log(date);  
      const formattedDate =
      date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
       date.getDate().toString().padStart(2, '0');
    
       
      setSearchEndDate(formattedDate);
      setDisplayEndDate(date);
  };
  const handleSearchSubmit = () => {
      setPage(1);
      getFileList(1, searchDate, searchEndDate,searchValue);
  };

  const clearFilter = () => {
    
    setSearchDate("");
    setSearchEndDate("");
    setDisplayDate("");
    setDisplayEndDate("");
    setSearchValue("");

    setPage(1);
    getFileList(1,'', '','');
  }

  const getFileList = async (page, date, enddate,searchValue) => {
    // console.log(page);
    setPopupOpen(true);
    const response = await axios.get(`${baseUrl}/get-citibank-uptivity-list?page=${page}&limit=${20}&date=${date}&enddate=${enddate}&name=${searchValue}`);
  
    setPopupOpen(false);
    setFileList(response.data.data);
    setTotalItem(response.data.totalItem);
}

  const fetchData = async () => {
    try {
      setPopupOpen(true);
      const response = await axios.get(`${baseUrl}/get-uptivity-list`);
      console.log(response.data.results);
      // setData(response.data.results);
      setFolders(response.data.folders);
      setFiles(response.data.files);
      setPopupOpen(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setPopupOpen(false);
    }
  };

  const handlePageChange  = (pageNumber) => {
    // console.log(`active page is ${pageNumber}`);
    setPage(pageNumber);
  }

  const handleDownload = async(item) => {

    setPopupOpen(true);
    const fullPath = item.path;
    console.log(fullPath, "path")
    const response = await axios.post(`${baseUrl}/download-uptivity-file`, {path : fullPath}, 
    {responseType: 'blob' // Set the response type to 'blob' to handle binary data
  });
  
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const url = window.URL.createObjectURL(blob);

    // Create a temporary link element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', item.name); // Set the downloaded file name
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    link.remove();
    setPopupOpen(false);
  };



  const refreshToken = async () => {
    try {
      
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/token`);
        
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        console.log(decoded);
        setName(decoded.name);
        seTimeZone(decoded.timezone);
        setRole(decoded.role)
        setExpire(decoded.exp);
        setUserId(decoded.userId);
        // setPopupOpen(false);
    } catch (error) {
       
        if (error.response) {
            // setError("Unauthorized");
            navigate("/");  
            window.location.reload();
        }
    }
  }

  const checkAccess = async () => {
    
    navigate('/');
    
  }

  const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

  return (
    <div className="panelbox">
      <Navbar token={token} access={access}/>
     <div className="right-panel">
     <Header name={name} />
     <div className="content-page admin-attendance-page">               
         <div className="row">
             <div className="col-8">
                 <div className="left-title">
                     <h1> Citibank Recordings</h1>
                 </div>
             </div>
             <div className="col-4 top-right-buttons"> 
                                    
             {/* <div className="export-btn addnew-button"><a onClick={() => handleBack()}><span>&larr;</span> Back</a></div> */}
             </div>
             
         </div>
         {popupOpen && (
                     <Loader /> 
                ) }
         <div className="fullrow">
        
             {/* <div className="row admin-staff-list-toppart">
                 <div className="col-md-8">
                     <div className="boxinput">
                     {renderBreadcrumbs()}
                     </div>
                 </div>
                 
             </div> */}
             <div className="row">
                        <div className="col-md-12 order-filter transection-filter">
                            <div className="boxinput" style={{margin : "20px 0px"}}>

                             
                                <DatePicker
                                    selected={displayDate}
                                    onChange={handleSearchDate}
                                    dateFormat="MM/dd/yyyy" // Set the desired date format
                                    placeholderText="From Date"
                                    className="from-date"
                                    showMonthDropdown // Enables month dropdown
                                    showYearDropdown // Enables year dropdown
                                    yearDropdownItemNumber={10} // Number of years to display in the dropdown
                                    scrollableYearDropdown 
                                />
                                <DatePicker
                                    selected={displayEndDate}
                                    onChange={handleSearchEndDate}
                                    dateFormat="MM/dd/yyyy" // Set the desired date format
                                    placeholderText="To Date"
                                    className="end-date"
                                    showMonthDropdown // Enables month dropdown
                                    showYearDropdown // Enables year dropdown
                                    yearDropdownItemNumber={10} // Number of years to display in the dropdown
                                    scrollableYearDropdown 
                                />     
                             
                                <input className="search-leave" type="text" name="file_name_search" placeholder="Search" value={searchValue} onChange={handleSearchChange} /> &nbsp;&nbsp;

                                <button  className="search-btn" onClick={handleSearchSubmit}>Submit</button>
                               { (searchDate || searchEndDate || searchValue) && (<a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>)}
                            </div>
                        </div>
                        
                    </div>
             <div className="row">
                 <div className="col-12">
                     <div className="setting-section haft-whitebox">                                
                     {fileList && fileList.length == 0 && <div className="common-white-shadow-background setting-section">
                        <div className="row error">No Record Found.</div>
                    </div>}


      {fileList && fileList.length > 0 && <div className="common-white-shadow-background setting-section">
        <table className='tablebox search-result-table'>
        <thead>
          <tr>
          <th>Date</th>
            <th>Audio File</th>
            <th>Extension</th>
            <th>Action</th>
          </tr>
        </thead>
            <tbody>
              {fileList.length > 0 && fileList.map((item, index) => (
                <tr key={index}>
                <td>{item.date.split("T")[0]}</td>
                <td>{item.name}</td>
                <td>{item.name.split("-")[0]}</td>
                <td><a className="breadcrumb-link" onClick={() => handleDownload(item)}><img src="/images/download.png" /><span style={{marginLeft: "20px"}}></span></a></td>
                </tr>
              ))}
            </tbody>
        {/* <tbody>
        {folders.map((folder, index) => (
          <tr key={index}>
          <td><a className="breadcrumb-link" onClick={() => handleFolderClick(folder)}><img src="/images/folder.png" /><span style={{marginLeft: "20px"}}>{folder}</span></a></td>
          <td>
            
          </td>
        </tr>
        ))}
        {files.map((file, index) => {
           const filePath = typeof file === 'object' ? Object.values(file)[0] : file;
           return (
          <tr key={index}>
          <td><img src="/images/file.png" /><span style={{marginLeft: "20px"}}>{filePath}</span></td>
          <td>
            <a
            className="breadcrumb-link"
              
              onClick={() => handleFileDownload(filePath)}
            >
              <img src="/images/download.png" />
            </a>
          </td>
        </tr>
)})}
        </tbody> */}
      </table>
    </div>}
      {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                     </div>
                 </div>
             </div>
         </div>
     </div>                
     </div>
 </div>
);
};



 
export default CitibankUptivityAnalytics