
import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios';
import baseUrl from "./BaseUrl";
import jwt_decode from "jwt-decode";
import { useNavigate, Link } from 'react-router-dom';
import Loader from "./Loader";
 
const Navbar = (props) => {
    const navigate = useNavigate();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [toogleSidebar, setToogleSidebar] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [applicationList, setApplicationList] = useState([]);
    const [iframeUrl, setIframeUrl] = useState(null);
    // const [token, SetToken] = useState(props.token);
    const [role, setRole] = useState('');
    const [cid, setCid] = useState('');
    const toggleRef = useRef(null);
    const [userId, setUserId] = useState('');
    const [casAccess, setCASAccess] = useState(false);
    // const [userId, setUserId] = useState('');
    useEffect(() => {
        if (props.token) {
          try {
            const decoded = jwt_decode(props.token);
            setCid(decoded.cid);
            setRole(decoded.role);
            setUserId(decoded.userId);
          } catch (error) {
            // Handle invalid token or decoding error
            console.error('Error decoding token:', error);
          }
        }
      }, [props.token]);
      useEffect(() => {
        if (role >=3) {
            getApplicationList();
        }
        if(cid){
            getCustomerTNSAccess();
        }
    }, [role, cid]);

    const getApplicationList = async () => {
        try{
            setPopupOpen(true);
           
            const response = await axios.get(`${baseUrl}/application-name-list/${cid}`, {
                headers: {
                    Authorization: `Bearer ${props.token}`
                }
            });
            setPopupOpen(false);
            setApplicationList(response.data.data);
        }catch (error) {
            console.log(error);
            setPopupOpen(false);
        }
    }
    const Logout = async () => {
        try {
            setPopupOpen(true);
            await axios.delete(`${baseUrl}/logout`);
            navigate("/");
        } catch (error) {
            console.log(error);
            setPopupOpen(false);
        }
    }

    const getCustomerTNSAccess = async () => {
        try {
            if(role <=2 ){
                setCASAccess(true)
            }else if(role > 2){
                const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                    headers: {
                        Authorization: `Bearer ${props.token}`
                    }       
                });
                console.log(response.data);
                setCASAccess((response.data.cia_enable_switch === 1 && response.data.cia_account_type === 1) ? true : false);
            }

        } catch (error) {
            // setLoading(false);
            
           console.log(error);
            
        }
    };

    const closeIframe = () => {
        setIframeUrl(null);
    };

    const hasPermission = (permission) => {
        if (props.access && props.access.length > 0) {
            return props.access.some((item) => item.access === permission);
        }
        return false;
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const handleApplication = (e,url) => {
        e.preventDefault();
        setIframeUrl(url);
    }

    const handleToogleSidebar = () => {
        const value = !toogleSidebar;
        
        if (toggleRef.current) {
            const parentElement = toggleRef.current.closest('.panelbox');
            if (value && parentElement) {
                parentElement.classList.add('collaps-wrap'); // Add class to parent
            }
            if(!value && parentElement){
                parentElement.classList.remove('collaps-wrap'); // Add class to parent
            }
        }
        setToogleSidebar(value);
    }
 
    return (
        
        <div className={`${toogleSidebar ? "collaps-sidebar" : ""} left-sidebar`}>
            {popupOpen && (
                     <Loader /> 
                ) }
        <div className="logo-div">
            <img src={`${process.env.PUBLIC_URL}/images/innerlogo.png`} alt='' />
            <a href="#" ref={toggleRef} onClick={handleToogleSidebar}><img src={`${process.env.PUBLIC_URL}/images/menubar.png`} alt="" /></a>

        </div>
        <div className="mainmenu" style={mobileMenuOpen ? {display: 'block'}: {}}>
            <div className="mobilemenu-close">
                <a href="#" onClick={toggleMobileMenu}><img src={`${process.env.PUBLIC_URL}/images/close_white.png`} alt="" /></a>
            </div>
            <ul>
                <li>
                    <Link to="/dashboard"><img src={`${process.env.PUBLIC_URL}/images/layers.png`} alt='' /> <span>Dashboard</span></Link>
                </li>
                {role !== 5 && hasPermission('viewuser') && <li>
                    <Link to="/user"><img src={`${process.env.PUBLIC_URL}/images/bx-user.png`} alt='' /> <span>User Management</span></Link>
                </li>}
                {(role !== 4 && role !== 5 && role !== 3) && hasPermission('viewuser') && <li>
                    <Link to="/user-company/list"><img src={`${process.env.PUBLIC_URL}/images/bx-user.png`} alt='' /> <span>Customer Management</span></Link>
                </li>}
                <li>
                    <Link to="/number-management"><img src={`${process.env.PUBLIC_URL}/images/phone-call.png`} alt='' /> <span>Number Management</span></Link>
                </li>
                {role <= 2 && <li>
                    <Link to="/trunk-cron-list"><img src={`${process.env.PUBLIC_URL}/images/etns.png`} alt='' /><span>Trunk Sync Cron</span></Link>
                </li>}
                {casAccess && <li>
                    <Link to="/cas-management"><img src={`${process.env.PUBLIC_URL}/images/etns.png`} alt='' /><span>E-NRMS Dashboard</span></Link>
                </li>}
                {(role < 2 || userId == 94 || userId == 95 || userId == 106) && <li>
                    <Link to="/uptivity"><img src={`${process.env.PUBLIC_URL}/images/etns.png`} alt='' /><span>Uptivity</span></Link>
                </li>}
                {(role < 2 || userId == 94 || userId == 95 || userId == 106) && <li>
                    <Link to="/citibank-uptivity"><img src={`${process.env.PUBLIC_URL}/images/etns.png`} alt='' /><span>Citibank Uptivity</span></Link>
                </li>}
                {applicationList.length > 0 && applicationList.map((item, index) => (
                    
                    <li key={index}>
                        <a href="#" onClick={(e) => {handleApplication(e, item.url)}}><img src={`${process.env.PUBLIC_URL}/images/etns.png`} alt='' /><span>{item.name}</span></a>
                    </li>
                    
                ))}
                {/* {( role == 3 || role == 4 || (role == 5 && hasPermission('viewuser'))) && <li>
                    <Link to={`/current-config/${props.token}`}><img src={`${process.env.PUBLIC_URL}/images/settings.png`} /> Current Config</Link>
                </li>} */}
                <li>
                    <a href="#" onClick={(e) => { e.preventDefault(); Logout(); }}><img src={`${process.env.PUBLIC_URL}/images/logout.png`} alt='' /> <span>Log out</span></a>
                </li>
            </ul>
        </div>
        {!mobileMenuOpen && <div className="mobilemenu" onClick={toggleMobileMenu}>
            <a href="#"><img src={`${process.env.PUBLIC_URL}/images/menubar.png`} alt='' /></a>
        </div>}
        {iframeUrl && (
            <div className="iframe-wrap" >
                <iframe 
                    src={iframeUrl}
                    className="iframe"
                    
                    title="Fullscreen Iframe"
                />
                {/* <a 
                    onClick={closeIframe} 
                    className='iframe-close'
                    >
                    <img src={`${process.env.PUBLIC_URL}/images/bx-x-circle.png`}  alt="Close" title="Close" />
                </a> */}
            </div>
        )}
    </div>
    )
}
 
export default Navbar