
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import { useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
 
const ENRMSGroupSetup = () => {

    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [carrierPage, setCarrierPage] = useState(1);
    const [popupOpen, setPopupOpen] = useState(false);    
    const [msg, setMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [newCarrierRow, setNewCarrierRow] = useState(null);
    const [carrierErrors, setCarrierErrors] = useState({});
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [totalItem, setTotalItem] = useState(0);
    const [carriers, setCarriers] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [isAdd, setIsAdd] = useState(false);
    const [access, setAccess] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);

    useEffect(() => {
        if(role >= 2){
            checkAccess();
        }
        
    }, [role]);

    useEffect(() => {
        if(token){
            getCarrierList();
        }
    }, [token, carrierPage]);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const checkAccess = async () => {
        navigate('/dashboard');
    }

    const getCarrierList = async () => {
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/get-carrier-management-list?page=${carrierPage}&limit=${20}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        setCarriers(response.data.data);
        setTotalItem(response.data.totalItem);
    }

    const handlePageChange  = (pageNumber) => {
        setCarrierPage(pageNumber);
    }

    // Add a new row
    const handleAdd = (e) => {
        e.preventDefault();
        setNewCarrierRow({ id: "", name: "" });
        setCarrierErrors({});
        setIsAdd(true);
    };

    const handleEdit = (e,index) => {
        e.preventDefault();
        const carrier = carriers[index];
        setNewCarrierRow(carrier);
        setIsAdd(false);
        setEditIndex(index);
        setCarrierErrors({});
    };

    

    // Handle input change for the new row
    const handleInputChange = (e, field) => {
        setNewCarrierRow({ ...newCarrierRow, [field]: e.target.value });
        setCarrierErrors({ ...carrierErrors, [field]: "" });
    };

    const validateCarrierInputs = () => {
        const carrierErrors = {};
        if (!newCarrierRow.name.trim()) carrierErrors.name = "Carrier Name is required.";
        setCarrierErrors(carrierErrors);
        return Object.keys(carrierErrors).length === 0;
    };

    // Save the new row
    const handleCarrierAdd = async (e) => {
        e.preventDefault();

        if (!validateCarrierInputs()) return;
        try {
            setButtonDisabled(true);
            setPopupOpen(true);
            const response = await axios.post(`${baseUrl}/get-carrier-management-action`, newCarrierRow, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setPopupOpen(false);
            setIsSuccess(true);
            setMsg(response.data.msg);
            window.location.reload();
        } catch (error) {
            setButtonDisabled(false);
            setPopupOpen(false);
            if (error.response) {
                setIsSuccess(false);
                setMsg(error.response.data.msg);
            }
        }
    };

    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    return (
        <div className="panelbox">
            <Navbar token={token} access={access}/>
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                    <Loader /> 
                ) }
                <div className="content-page admin-attendance-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Group Settings</h1>
                            </div>
                        </div>
                        <div className="col-6 inventory-btn">
                            <div className="addnew-button">
                                <Link to="/enrms-management"><span>&larr;</span> Back</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p>
                <div className="col-12 common-white-shadow-background setting-section">
                    <div className="row">
                        <div className="col-6"></div>
                        <div className="col-6 inventory-btn">
                            <div className="addnew-button">
                                <a href="#" onClick={handleAdd}>Add</a>
                            </div>
                        </div>
                    </div> 
                    <div className="fullwidth-table track-table-body staff-listing-admin-table-body cia-carrier-list">
                        <table>
                            <thead>
                            <tr>

                                <th>Id</th>
                                <th>Name</th>
                                <th>Edit</th>
                            </tr>
                            </thead>
                            <tbody>
                            {carriers.map((row, index) => (
                            <tr key={index}>
                                <td>{row.id}</td>
                                <td>
                                    {editIndex === index ? 
                                        <>
                                        <input
                                            type="text"
                                            value={newCarrierRow.name}
                                            onChange={(e) => handleInputChange(e, "name")}
                                        />
                                        {carrierErrors.name && <div className="error error-message">{carrierErrors.name}</div>}
                                        </> : 
                                        row.name
                                    }
                                </td>
                                <td>
                                    {editIndex === index && <a href="#" className={buttonDisabled ? 'disabled-link' : ''}  onClick={handleCarrierAdd} ><img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="Update" title="Use this option to edit carrier" /></a>}
                                    <a href="#" onClick={(e) => handleEdit(e,index)}><img src={`${process.env.PUBLIC_URL}/images/bx-edit.png`} alt="Edit" title="Use this option to edit carrier" /></a></td>
                            </tr>
                        ))}
                            {isAdd && newCarrierRow && (
                            <tr>
                                <td>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={newCarrierRow.name}
                                        onChange={(e) => handleInputChange(e, "name")}
                                    />
                                     {carrierErrors.name && <div className="error error-message">{carrierErrors.name}</div>}
                                </td>
                                <td>
                                    <a href="#" className={buttonDisabled ? 'disabled-link' : ''} onClick={handleCarrierAdd}><img src={`${process.env.PUBLIC_URL}/images/add.png`} alt="Add" title="Use this option to add carrier" /></a>
                                </td>
                            </tr>
                        )}
                            </tbody>
                        </table>
                        {totalItem > 20 && (<Pagination
                                  activePage={carrierPage}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                    </div>
                </div>
            </div>
                </div>
        
            </div>
            </div>
    )
}
 
export default ENRMSGroupSetup
