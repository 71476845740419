import React, { useEffect } from 'react';

const PopupImage = ({ data, onClose }) => {
  useEffect(() => {
    //console.log("Popup Two opened with data:", data);
  }, [data]);

  return (
    <div className="popup-overlay">
      <div className="popup">
      {data && (
           <div className="image-container">
           <img src={data} alt="Screenshot" className="centered-image" width={450} height={450} />
         </div>
        )}
        <button className='apply-btn' onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default PopupImage;
