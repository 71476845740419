import React, { useState, useEffect } from 'react'
import axios from 'axios';
import baseUrl from "./BaseUrl";
import Loader from "./Loader";
import { useNavigate, Link } from 'react-router-dom';
 
const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            if(response.msg == 'unauthentication'){
                navigate("/");  
            }else if(response.data.accessToken){
                // setToken(response.data.accessToken);
                // const decoded = jwt_decode(response.data.accessToken);
                navigate("/dashboard");  
            }
        } catch (error) {
            if (error.response) {
                // setError("Unauthorized");
                //navigate("/");  
                //window.location.reload();
            }
        }
    }
    const Auth = async (e) => {
        e.preventDefault();
        try {
            setPopupOpen(true);
            await axios.post(`${baseUrl}/login`, {
                email: email,
                password: password
            });
            setPopupOpen(false);
            setIsSuccess(true); // Set success state to true
            setMsg('Login successful');
            navigate("/dashboard");
        } catch (error) {
            setPopupOpen(false);
            if (error.response) {
                setIsSuccess(false);
                setMsg(error.response.data.msg);
            }
        }
    }
 
    return (
        
        <section className="ragister-login loginpage">
            {popupOpen && (
                     <Loader /> 
                ) }
            <div className="row d-flex align-items-center">
                <div className="col-md-5 left-box">
                    <div className="inner-slider">

                    </div>
                </div>
                    <div className="col-md-7 right-box">
                        <div className="form-body">
                            <div className="left-logo">
                                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" />
                            </div>
                            <form onSubmit={Auth} className="form-box login-logo">
                                <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p>
                                <div className="field mt-5">
                                    {/* <label className="label">Email or Username</label> */}
                                    <div className="controls">
                                        <input type="email" className="input" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    {/* <label className="label">Password</label> */}
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="forgot-pass-link"><Link to="/forgot-password">Forgot Password?</Link></div>
                                </div>
                                <div className="field mt-5 logindiv">
                                    {/* <button className="button is-success is-fullwidth">Login</button> */}
                                    <input type="submit" value="Sign In"></input>
                                </div>
                            </form>
                        </div>
                    </div>
                
            </div>
        </section>
    )
}
 
export default Login
 