/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Pagination from "react-js-pagination";
import Loader from "../Loader";
import { useNavigate, Link, useLocation } from 'react-router-dom';
axios.defaults.timeout = 300000; 

const EditMultipleTrunkDetail = () => {
    const location = useLocation();
    const abx = location.state;
    const [name, setName] = useState('');
    const [cid, setCid] = useState("");
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [details, setDetails] = useState([]);
    const [policy, setPolicy] = useState('');
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [detail, setDetail] = useState([]);
    const [page, setPage] = useState(1);
    const [trunkRouteName, setTrunkRouteName] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);
    const [numberId, setNumberId] = useState('');
    const [selectedNumber, setSelectedNumber] = useState('');
    const [trunkDetail, setTrunkDetail] = useState([]);
    const [selectedPolicy, setSelectedPolicy] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [isSansayEdit, setIsSansayEdit] = useState(false);
    const [inventoryAccess, setInventoryAccess] = useState(false);
   
    
    const navigate = useNavigate();
    
    useEffect(() => {
        refreshToken();
        getUserAccess();
        // getTrunkDetail(1);
        
    }, []);

    useEffect(() => {
        console.log(abx);
        setCid(abx.cid);
    }, [abx]);
    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
    }, [access, token]);

    useEffect(() => {
        if(policy){
            setSelectedPolicy(policy);
        }
        
        if(cid){
            console.log(cid);
            getTrunkName();
            getCustomerDigitMappingAccess();
        }
        if(details){
            const numberOfBlanks = Math.max(7 - details.length, 0);

            const additionalObjects = Array.from({ length: numberOfBlanks }, () => ({
                "selectedOption": "",
                "inputText": '',    
                "yesNo": ""
            }));


            const transformedData = details.map(item => ({
                
                "selectedOption": item.route_id, // Rename route_id to select
                "inputText": item.percentage, // Rename percentage to inputText                
                "yesNo": item.continuous_route // Rename continuous_route to yesno
            }));
            // console.log(details);
            setTableData(details ? transformedData.concat(additionalObjects) : [
                {
                  selectedOption: '',
                  inputText: '',
                  yesNo: '',
                  
                },
                {
                  selectedOption: '',
                  inputText: '',
                  yesNo: '',
                  
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                }
            ]);
        }
        
    }, [policy, cid, details]);

    const [tableData, setTableData] = useState([
        {
          selectedOption: '',
          inputText: '',
          yesNo: '',
          
        },
        {
          selectedOption: '',
          inputText: '',
          yesNo: '',
          
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
            
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
            
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
           
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
           
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
           
        }
    ]);
    const getTrunkName = async () => {
        console.log(cid);
        const response = await axiosJWT.get(`${baseUrl}/get-trunk-name/${cid}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }       
        });
        const allArray = response.data;
        setTrunkRouteName(allArray.data);
        setCid(cid);
        console.log(allArray.data);
        setIsSansayEdit(true);
    }


    const handleRowChange = (e, index, fieldName) => {
        const newValue = e.target.value;
        // const newValue = JSON.parse(e.target.value); // Parse the JSON string
        console.log(newValue); // Access the id
      
        
        setTableData((prevData) => {
            const updatedData = prevData.map((row, rowIndex) => {
                if (rowIndex === index) {
                    // Update the field for the specific row
                    return {
                        ...row,
                        [fieldName]: newValue
                      
                    };
                }
                // Return other rows as they are
                return row;
            });
            return updatedData;
        });
    };


    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setName(decoded.name);
            setCid(decoded.cid);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const handlePolicyChange = (event) => {
        setSelectedPolicy(event.target.value);
    };
    // const getTrunkDetail = async (page) => {

        
    //     const response = await axiosJWT.get(`${baseUrl}/trunk-detail/${atob(id)}?page=${page}&limit=${50}`, {
    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }
    //     });
    //     console.log(response.data);
    
    //     // setDetails(response.data.results);
    //     // if(response.data.results && response.data.results.length > 0){
            
    //     //     setSelectedPolicy(response.data.results[0].policy);
    //     //     setCid(response.data.results[0].cid);
    //     // }
    //     // setNumberId(response.data.number_detail[0].id);
    //     // setSelectedNumber(response.data.number_detail[0].tn_number)
    //     // setDetails(response.data.trunk_detail);
    //     // setSelectedPolicy(response.data.number_detail[0].policy);
    //     // setCid(response.data.number_detail[0].cid);

    // }

    const updateTrunkRoute = async (e) => {
       e.preventDefault();
      
        const error = validateTrunkData(tableData, selectedPolicy );
        setValidationErrors(error);
        const userConfirmed = window.confirm(`Are you sure you want to edit selected ${abx.selectedItems.length} number?`);
        if (userConfirmed) {
            if(error.length === 0){
            
                const combaineData = {
                    tableData: tableData,
                    policy: selectedPolicy,
                    tn_id: details.map(option => option.invenotry_id),
                    tn_number : selectedNumber,
                    company_id: cid,
                    number_id:numberId,
                    list : abx.selectedItems,
                    sansay_edit:isSansayEdit
                }
                console.log(combaineData);
                setButtonDisabled(true);
                setPopupOpen(true);
                const response = await axiosJWT.post(`${baseUrl}/update-trunk-detail`, combaineData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }       
                });
                setPopupOpen(false);
                console.log(response);
                setButtonDisabled(false);
                if(response.data.status){
                    navigate('/inventory');
                }
                console.log("api call");
            }
        }
    }

    const getCustomerDigitMappingAccess = async () => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            console.log(response.data);
          
            setInventoryAccess(response.data.inventory_switch === 1 ? true : false);
           
        } catch (error) {
            // setLoading(false);
            
           console.log(error);
            
        }
    };

    function validateTrunkData(trunkdata, policy) {
        const errors = [];
       
        if (!policy) {
            errors.push(`Policy field is required.`);
            // errors.push(`Please Fill proper trunk value for ${mobileKey}`);
        }
        const hasTableDataValue = trunkdata.some(tableItem =>
            tableItem.selectedOption 
        );

        if (!hasTableDataValue) {
            errors.push(`Atleast one route is required.`);
            // errors.push(`Please Fill proper trunk value for ${mobileKey}`);
        }else{
            
            const validKeys = trunkRouteName.map(option => option.value);
            
            const selectedOptions = trunkdata.map(item => item.selectedOption.toString());
            const isValidSelectedOption = selectedOptions.some(option => validKeys.includes(option));
           
            if (!isValidSelectedOption) {
                errors.push("Please select valid route.");
            }else{
                const isValid = trunkdata.every((item, index, arr) => {
                    if (index == 0) {
                        return item.selectedOption !== "";
                    }
                    // Check if the current item has a value for selectedOption
                    const hasValue = item.selectedOption !== "" && validKeys.includes(item.selectedOption.toString());
                    // Check if the previous item also has a value for selectedOption
                    const previousHasValue =  arr[index - 1].selectedOption !== ""  && validKeys.includes(arr[index - 1].selectedOption.toString());
                    console.log(hasValue);
                    console.log(arr[index - 1].selectedOption);
                    console.log(index);
                    // Ensure that if the current item has a value, the previous one must also have a value
                    if (!hasValue ||  !previousHasValue) {
                       
                        item.selectedOption = ""; // Set to blank if it doesn't meet the criteria
                        item.inputText = "";
                        item.yesNo = "";
                    }
                    return !hasValue || (hasValue && previousHasValue);
                });
                setTableData(trunkdata);
            
                if (!isValid) {
                    errors.push("Please select index wise valid route.");
                }
            }
            
          
            
        } 
         if (policy == "2") {
            const inputTextValues = trunkdata.map(tableItem => Number(tableItem.inputText));
            const hasUserInput = inputTextValues.some(value => value > 0);
           
            if (!hasUserInput) {
                errors.push(`Percentage values are required.`);
                // errors.push(`Please Fill proper trunk value for ${mobileKey}`);
            } else {
              const sumOfInputTextValues = inputTextValues.reduce((acc, value) => acc + value, 0);

              if (sumOfInputTextValues !== 100) {
                errors.push(`Sum of percentage is not equal to 100.`);
                // errors.push(`Please Fill proper trunk value for ${mobileKey}`);
              }
            }
        }
        
            return errors;
    }
    
    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {
        if(!hasPermission('ordernewnumber') || !inventoryAccess){
            navigate('/dashboard');
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setCid(decoded.cid);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const handleCheckboxChange = () => {
        setIsSansayEdit(!isSansayEdit);
    };
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page">
                    <div className="row ">
                        <div className="col-8">
                            <div className="left-title">
                                <h1>Trunk Details</h1>
                            </div>
                        </div>
                        <div className="col-4 edit-inventory-btn">
                            <div className="addnew-button">
                               <Link to="/inventory"> Cancel</Link>
                               <a href='#' className={buttonDisabled ? 'disabled-link' : ''} onClick={updateTrunkRoute}>Edit {abx.selectedItems.length} Numbers</a>
                            </div>
                        </div>
                        
                        
                    </div>
                    <div className="row order-detail trunk">
                        <div className="col-sm-6  trunk-detail">
                            <label>Policy</label>
                            <div className="switchbtn">
                                <select value={selectedPolicy} onChange={handlePolicyChange} >
                                    <option value="">Select Policy</option>
                                    <option value="1">top_down</option>
                                    <option value="2">percentage</option>
                                    <option value="3">round_robin</option>
                                </select>
                            </div>
                        </div>   
                        {role <= 2 && <div className='col-sm-6 edit_checkbox'>
                            <label>
                            <input type="checkbox" checked={!isSansayEdit} onChange={handleCheckboxChange} />
                            Don't Edit in Sansays
                            </label>
                        </div> } 
                    </div>
                    {validationErrors.map((value, index) => (<p style={{"color":"red"}} key={index}>{value}</p>))}
                    <div className='fullwidth-table track-table-body staff-listing-admin-table-body'>
        
            <table>
                <thead>
                    <tr>
                    <th>Route</th>
                    <th>Load Percentage</th>
                    <th>Continuous routing</th>
                    </tr>
                </thead>
                <tbody>
                {tableData.map((row, index) => (
                    <tr key={index + 1}>
                        <td>
                            <select  value={row.selectedOption}
            onChange={(e) => handleRowChange(e, index, 'selectedOption')}>
                              <option value="">Select Route</option>
                              {trunkRouteName.map((item, index) => (
                                <option key={index} value={item.value} >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                        </td>
                        <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <input
                                type="number"
                                value={row.inputText}
                                onChange={(e) => handleRowChange(e, index, 'inputText')}
                              />
                              <span style={{ marginLeft: '5px', width: '50px', height: '25px' }}>
                                %
                              </span>
                            </div>
                        </td>
                        <td>
                            <select value={row.yesNo}
            onChange={(e) => handleRowChange(e, index, 'yesNo')}>
                                {/* <option value="">Select</option> */}
                                <option value="1">Yes</option>
                              <option value="0">No</option>
                              
                            </select>
                        </td>
           </tr> 
          ))}
          
                    

                </tbody>
            </table>
            
            {/* <div className='save-btn'><button onClick={handleSave}>Save</button></div> */}
            <div className="col-md-6">
                
            </div>
        </div>
                    {/* <div className="common-white-shadow-background setting-section">
                        <div className="row">
                        <div className=" setting-section haft-whitebox">
                            <div className="search-result-section">
                                <h3></h3>
                                
                            </div>
                                <table className="tablebox search-result-table">
                                    <thead>
                                        <tr>
                                            <th>Route</th>
                                            
                                            <th>Percentage</th>
                                            
                                            <th>Continuous Route</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {details.map((trunk, index) => (
                                        <tr>
                                        <td>{trunk.customer_name}</td>
                                        <td>{trunk.percentage}</td>
                                        <td>{trunk.continuous_route == 0 ? 'No' : 'Yes'}</td>
                                        
                                       
                                        </tr>))}
                                        
                                         </tbody>
                                </table>
                                
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="row order-detail">
                        <div className="col-4">
                            <div><strong>Status:</strong> {detail.message}</div>                            
                        </div>
                        <div className="col-4">
                           <div><strong>Status Code:</strong> {detail.status_code}</div>
                           
                        </div>
                       
                    </div> */}
                </div>
            </div>
        </div>
    )
}
 
export default EditMultipleTrunkDetail