/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import { useNavigate, Link } from 'react-router-dom';
import Modal from 'react-modal';
import Step1 from './CreateOrderStepOne';
import Step2 from './CreateOrderStepTwo';
import Step3 from './CreateOrderStepThree';
axios.defaults.timeout = 300000; 
// import Step4 from './CreateOrderStepFour';

const Step4 = ({ onBack, onNext, token, formData, setFormData }) => {
    useEffect(() => {
        // Make the API call when the component mounts
      console.log(formData);
    }, [formData]);
    const [contacts, setContacts] = useState([
        { name: '', phone: '', extension: '', alias: '' }
    ]);
    const [validationErrors, setValidationErrors] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [orderInstrunction, setOrderInstrunction] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [role, setRole] = useState("");
    const [companyEmail, setCompanyEmail] = useState("");
    const [copmanyName, setCopmanyName] = useState("");
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    
    const navigate = useNavigate();
    useEffect(() => {
        if (validationErrors.length > 0) {
            const errorMessages = validationErrors.join('\n');
            alert(errorMessages);
        }
    }, [validationErrors]);
    useEffect(() => {
        getCompanyEmail();
    }, [formData.selectedCompany]);
    useEffect(() => {
        refreshToken();
      }, []);

    const refreshToken = async () => {
        try {
            
            const response = await axios.get(`${baseUrl}/token`);
            
            
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setUserEmail(decoded.email);
            setRole(decoded.role);
            
        } catch (error) {
           console.log(error);
            
        }
    }

    const getCompanyEmail = async () => {
        try {
            
            const response = await axios.get(`${baseUrl}/get-company-email/${formData.selectedCompany}`);
            console.log(response.data[0]);
            setCompanyEmail(response.data[0].email);
            setCopmanyName(response.data[0].name);
            
        } catch (error) {
           console.log(error);
            
        }
    }

    const handleAddContact = (e) => {
        e.preventDefault();
        setContacts([...contacts, { name: '', phone: '', extension: '', alias: '' }]);
    };

    const toggleOrderInstruction = () => {
        setOrderInstrunction(!orderInstrunction);
    };

    const handleCheckboxChange = (event) => {
        setIsCheckboxChecked(event.target.checked);
    };


    const handleRemoveContact = (index) => {
        const updatedContacts = contacts.filter((_, i) => i !== index);
        setContacts(updatedContacts);
    };
    const handleContactChange = (e, index) => {
        const { name, value } = e.target;
        const updatedContacts = [...contacts];
        const fieldName = name.split('-')[0];
        updatedContacts[index][fieldName] = value;
    
        setContacts(updatedContacts);
    };
    

    const contactInputs = contacts.map((contact, index) => (
        <div className="row order-contect" key={index}>
        <div className="labelwithbox" >
            <label>Name</label>
            <div className="inputbox">
                <input
                    type="text"
                    name={`name-${index}`}
                    value={contact.name}
                    onChange={(e) => handleContactChange(e, index)}
                    required
                />
            </div> </div>
            
                 <div className="labelwithbox">
                    <label>Email</label>
                    <div className="inputbox">
                        <input type="email" name={`alias-${index}`} value={contact.alias}
                    onChange={(e) => handleContactChange(e, index)} required />
                    </div>
                </div>
                
        </div>
       
    ));
    
    const handleNext = async(e) => {
        e.preventDefault();
        const errors = contacts.map((contact, index) => {
            const contactErrors = [];

            // if (!contact.name) {
            //     contactErrors.push(`Row ${index + 1}: Name is required`);
            // }
            // if (!/^\d{3}-\d{3}-\d{4}$/.test(contact.phone)) {
            //     contactErrors.push(`Row ${index + 1}: Phone is invalid and format is 111-111-1111`);
            // }
            // if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact.alias)) {
            //     contactErrors.push(`Row ${index + 1}: Email is invalid`);
            // }

            return contactErrors;
        });
        setValidationErrors(errors.flat());

    if (errors.some(rowErrors => rowErrors.length > 0)) {
        return;
    }else if(isCheckboxChecked == false && role > 2){
        alert("Please accept the disclaimer to proceed");
        return;
    }else{
        
        const combaineData = {
            contacts: contacts,
            formData: formData
        }
        console.log(combaineData);
        try {
           
            setButtonDisabled(true);
            setPopupOpen(true);
            const response = await axios.post(`${baseUrl}/order-new-tnnumber`, combaineData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            const allArray = response.data;
            console.log(allArray);
            setLoading(false); 
            setPopupOpen(false);
            navigate("/order-new-number-success", { state: allArray });  
           
          
        } catch (error) {
            setLoading(false);
            setPopupOpen(false);
           console.log(error);
            
        }
    }
   
   
    };
    

    return(
    <>
        <div className="row">
            <div className="col-6">
                <div className="left-title">
                    <h1>Order New Number 
                    <span><img src={`${process.env.PUBLIC_URL}/images/view.png`} onClick={toggleOrderInstruction} style={{ cursor: 'pointer' }} /></span>
                    </h1>
                    
                    {/* <small>(Secondary Contact Detail Step 4)</small> */}
                </div>
            </div>
            <div className="col-6">
            </div>
        </div>
        {orderInstrunction && <div className="common-white-shadow-background setting-section">
            <div className="row">                
                <div className="introducebpx">
                    <p><strong>Instructions</strong></p>
                    <p>If you would like someone else to receive an email with the details of this order, enter their name and email address here.<br/>
                      Press "Complete Order" to complete the order.</p>
                </div>
            </div>
        </div>}
        <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
            <div className="row">
                <div className="order-con-title">Secondary Order contact</div>
            </div>
            {contactInputs}
           
        </div>
        <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
    <strong>Note:- </strong>Additional to secondary contact we will send order details to below email addresses.<br/>
    <ul>
        <li>{userEmail && userEmail}</li>
        <li>provisioning@broadbanddynamics.com</li>
        <li>{companyEmail && companyEmail}</li>
    </ul>
</div>
{role > 2 && <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
            
            <label htmlFor="disclaimerCheckbox">
            <input
                type="checkbox"
                id="disclaimerCheckbox"
                checked={isCheckboxChecked}
                onChange={handleCheckboxChange}
            /> &nbsp; By submitting this order, I confirm that {copmanyName} meets all regulatory requirements, including adhering to local, state, and federal laws, to order and utilize these numbers and/or features.
            </label>
            <br />
        </div>}



        {/* <div className="row">
             <div className="required-txt"><span className="redtxt">*</span>Required</div>
        </div> */}
        <div className="row footer-row">
            <div className="footer-row-left">
                <a href="#" onClick={onBack} className="Back"> <img src="images/back-arrow.png" />Back</a>
            </div>
            <div className="footer-row-right">
            <Link className="Cancel" to={"/number-management"}>Cancel</Link>
                <a href="#" onClick={handleNext} className={buttonDisabled ? 'next disabled-link' : 'next'} >Complete Order</a>
            </div>
        </div>
        {popupOpen && (
        // <div className="processing-popup">
        //   <p>Processing...</p>
        // </div>
        <div className='overlay'><div className="custom-loader"></div></div>
      )}
    </>)
};
  
 
const CreateOrder = () => {
    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [cid, setCid] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [formData, setFormData] = useState([]);
    const [access, setAccess] = useState([]);
    const navigate = useNavigate();

    const handleNext = () => setStep(step + 1);
    const handleBack = () => setStep(step - 1);

    const handleFinish = async () => {
        try {
            console.log(formData);
          // Make API call to Node.js backend
        //   const response = await axios.post('/api/submit-form', formData);
        //   setApiResponse(response.data);
        } catch (error) {
          console.error(error);
        }
      };
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
        // getUsers();
    }, []);

    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
    }, [access, token]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }
    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };
    
    const checkAccess = async (access) => {
        if(!hasPermission('ordernewnumber')){
            navigate('/dashboard');
        }
    }

    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 

    const renderStep = () => {
        switch (step) {
          case 1:
            return <Step1 onNext={handleNext} cid={cid} role={role} token={token} formData={formData} setFormData={setFormData} />;
          case 2:
            return <Step2 onBack={handleBack} cid={cid} onNext={handleNext} role={role} token={token} formData={formData} setFormData={setFormData} />;
          case 3:
            return <Step3 onBack={handleBack} onNext={handleNext} role={role} token={token}  formData={formData} setFormData={setFormData} />;
          case 4:
            return <Step4 onBack={handleBack} onNext={handleNext} token={token} formData={formData} setFormData={setFormData} />;
        //   case 5:
        //     return <Step5 data={formData} onFinish={handleFinish} />;
          default:
            return null;
        }
      };
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
                <div className="content-page">
               
                {renderStep()}
                    {/* <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Number Management</h1>
                            </div>
                        </div>
                        <div className="col-6">

                        </div>
                    </div>
                    <div className="row numberboxes">
                        <div className="col-md-3">
                            <div className="dashboard-box" id="number">
                                <div className="imagebox">
                                    <img src="images/bxs-phone.png" />
                                </div>
                                <h3>Order New Number</h3>
                                <p>Order and modify phone numbers and features.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dashboard-box" id="userbox">
                                <div className="imagebox">
                                    <img src="images/bxs-edit.png" />
                                </div>
                                <h3>Modify Number</h3>
                                <p>Order and modify phone numbers and features.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dashboard-box" id="bbdbox">
                                <div className="imagebox">
                                    <img src="images/bxs-phone-off.png" />
                                </div>
                                <h3>Cancel Number</h3>
                                <p>Order and modify phone numbers and features.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dashboard-box" id="orderbox">
                                <div className="imagebox">
                                    <img src="images/bxs-cart.png" />
                                </div>
                                <h3>View Orders</h3>
                                <p>Order and modify phone numbers and features.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dashboard-box" id="inventory">
                                <div className="imagebox">
                                    <img src="images/bxs-paste.png" />
                                </div>
                                <h3>Inventory</h3>
                                <p>Order and modify phone numbers and features.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dashboard-box" id="viewtrans">
                                <div className="imagebox">
                                    <img src="images/bx-transfer-alt.png" />
                                </div>
                                <h3>View Transactions</h3>
                                <p>Order and modify phone numbers and features.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dashboard-box" id="importdid">
                                <div className="imagebox">
                                    <img src="images/bxs-file-import.png" />
                                </div>
                                <h3>Import DID inventory</h3>
                                <p>Order and modify phone numbers and features.</p>
                            </div>
                        </div>
                        
                    </div> */}
                </div>
            </div>
        </div>
    )
}
 
export default CreateOrder